import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Text } from 'theme-ui'


const Search = props => {
  return (
    <Layout {...props}>
      <Seo title='Søg fælge til din bil' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Søg fælge til din bil"
            subheader='Sammenlign priser.'
          />

          <Text>
            Siden er under udvikling
          </Text>
        </Main>
      </Stack>
    </Layout>
  )
}

export default Search
